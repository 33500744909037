.globalFooterWarp {
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  z-index: 1;
  flex-direction: column;
  color: var(--app-text-color);
  // border-top: 1px solid var(--app-line-bg-rgb);

  > .titleContainer {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;

    .logo {
      display: inline-block;
      width: 32px;
      height: 32px;
    }

    .name {
      display: inline-block;
      height: 32px;
      width: auto;
    }
  }

  > .subTitleContainer {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    margin: 13px 0;
  }

  > .section {
    display: flex;
    flex-direction: row;

    > .link {
      color: var(--app-text-60-color);
      // font-family: Attila-Semibold;
      transition: all 0.3s ease-in;
      // padding: auto 5px;
      margin: 0 5px 6px;
    }
  }
}

@media (min-width: 300px) {
  .globalFooterWarp {
    padding: 20px;

    .section {
      margin-bottom: 40px;

      .link {
        font-size: 16px;
        line-height: 103%;
        // margin-bottom: 8px;
        // padding: auto 5px;
        margin: 0 5px 6px;
      }
    }
  }
}

@media (min-width: 700px) {
  .globalFooterWarp {
    padding: 20px;

    .section {
      margin-bottom: 81px;

      .link {
        font-size: 16px;
        // margin-bottom: 16px;
        // padding: auto 5px;
        margin: 0 5px 6px;
      }
    }
  }
}

@media (min-width: 900px) {
  .globalFooterWarp {
    height: auto;
    padding: 20px;
    // flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .section {
      margin-bottom: 0;

      .link {
        font-size: 16px;
        // margin-bottom: 3px;
        // margin-left: 5px;
        // margin-right: 5px;
        margin: 0 5px 6px;

        &:hover {
          color: var(--app-link-hover);
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .globalFooterWarp {
    padding: 20px;

    .section {
      .link {
        font-size: 16px;
        // margin-bottom: 6px;
        // margin-left: 5px;
        // margin-right: 5px;
        margin: 0 5px 6px;
      }
    }
  }
}

