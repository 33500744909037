.errorContainer {
  background: var(--foreground-rgb);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 92vh;
  color: black;

  > img {
    height: 119px;
    margin-top: 3.5rem;
  }

  > span {
    font-size: 16px;

    a {
      color: #141414;
      text-decoration: underline !important;
      margin: 0 5px;
    }
  }

  > .title {
    margin: 0;
    padding-bottom: 1.5rem;
    font-size: 2.5rem;
    color: var(--app-text-color);
  }

  > .backLink {
    font-size: 2rem;
    color: red;
  }
}

@media (min-width: 320px) {
  .error-page-container {
    padding: 2rem;
  }
}

@media (min-width: 801px) {
  .error-page-container {
    padding: 3rem 4rem;

    > span {
      width: 80%;
    }
  }
}

