.globalHeaderWarp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--pietra-global-header-heigth);
  display: grid;
  grid-template-columns: 1fr 300px; // repeat(2, 1fr);
  background: var(--foreground-rgb);
  // border: 2px solid #000;
  padding: 0 2rem;
  transition: padding 0.2s ease;

  &.shopPage {
    padding-left: 25px;
  }

  .navSection {
    display: flex;
    align-items: center;
    color: var(--app-text-color);

    &:last-child {
      justify-content: flex-end;
    }

    > .navSearch {
      display: flex;
      align-items: center;
      width: 100%;

      .sparkStone {
        cursor: pointer;
        width: 50px;
        height: 50px;
        object-fit: contain;
        border-radius: 0;
        background: none;
        margin-right: 1rem;
      }
    }

    > .cartIcon {
      cursor: pointer;
      margin-left: 20px;
      transform: translateY(2px);
    }
  }

  .navTitleContainer {
    cursor: pointer;

    .catchPhrase {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0;

      .retroTitle {
        font-size: 26px;
      }

      .logo {
        display: inline-block;
        width: 32px;
        height: 32px;
      }

      .name {
        display: inline-block;
        height: 32px;
        width: auto;
      }

      > .poweredBy {
        margin-top: 3px;
        font-size: 12px;

        > .logo {
          margin-left: 10px;

          > .pietra {
            width: 40px;
            height: auto;
          }
        }
      }
    }
  }
}

