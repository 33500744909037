.shoppingCartWarp {
  position: relative;

  .shoppingCartBtnIcon {
    position: relative;
    cursor: pointer;

    img {
      width: 35px;
      height: auto;
    }

    &.large {
      img {
        width: 70px;
        height: auto;
      }
    }
  }

  > .searchInput {
    // width: 142px;
    // outline: none;
    position: relative;
    // width: -moz-available;
    height: 100%;
    padding: 5px 14px;
    border-radius: 20px;
    font-size: 16px;
    border: 1px solid;
    color: var(--app-text-color);
    background: var(--app-input-bg-rgb);

    :global {
      .next-antd-input {
        background-color: var(--app-input-bg-rgb) !important;
        color: var(--app-text-color);
        &::placeholder {
          color: #9b9b9b !important;
        }
      }
    }

    // &::-moz-placeholder {
    //   color: #9b9b9b !important;
    //   font-style: italic;
    //   font-size: 16px;
    // }

    // &:-moz-placeholder {
    //   color: #9b9b9b !important;
    //   font-style: italic;
    //   font-size: 16px;
    // }

    // &:-ms-input-placeholder {
    //   color: var(--app-text-color) !important;
    //   font-style: italic;
    //   font-size: 16px;
    // }
  }
}

