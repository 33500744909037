.navMobileSearchWarp {
  position: relative;

  :global {
    .anticon {
      font-size: 18px;
      color: var(--app-text-color);
    }
  }
}

.navSearchDrawerWarp {
  .searchWarp {
    width: 80%;
    height: auto;
    margin: 0 auto;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .shopSearchField {
      text-align: center;
      background-color: inherit;
      border-width: 0;
      border-radius: 0;
      width: 100%;
      resize: none;
      overflow: hidden;
      border-bottom: 1px solid var(--app-text-color);
      font-size: 34px;
      font-family: 'Attila-Semibold';
      color: var(--app-text-color);

      ::placeholder {
        color: red;
        font-size: 1.5em;
      }

      &:focus,
      &:active {
        outline: 0;
        box-shadow: none;
      }
    }

    .shopSearchTip {
      margin: 1rem auto;
      text-align: center;
      font-size: 16px;
      color: var(--app-text-color);
    }
  }

  :global {
    .ant-drawer-content {
      background: var(--foreground-rgb);

      .ant-drawer-header {
        border-bottom: none;

        .ant-drawer-header-title {
          justify-content: flex-end;

          .ant-drawer-close {
            margin-inline-end: 0;
            margin-block-start: 16px;
          }

          .anticon-close {
            font-size: 22px;
            color: #141414;
          }
        }
      }
    }
  }
}

