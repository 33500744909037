.mainContainer {
  cursor: pointer;
  padding-left: 10px;

  .content {
    display: flex;
    justify-content: center;

    img {
      width: 25px;
      height: 25px;
    }

    span {
      margin-left: 5px;
      color: var(--app-text-color);
    }
  }
}

